import { Box, Container } from '@mui/material';
import { type IntlShape } from 'react-intl';
import { type Locale } from 'models/locale';
import { theme } from 'styles/mui-theme';

export const PromoBanner = ({
    podcastReportBanner,
    locale,
    intl,
}: {
    podcastReportBanner: boolean;
    locale: Locale;
    intl: IntlShape;
}) => {
    const href =
        locale === 'en'
            ? 'https://info.tritondigital.com/2024-u.s.-podcast-report'
            : 'https://info.tritondigital.com/2024-u.s.-podcast-report';

    const bodyText = {
        download: intl.formatMessage({
            defaultMessage: 'Download',
            description: 'Banner download text',
        }),
        message: intl.formatMessage({
            defaultMessage: 'the 2024 U.S. Podcast Report',
            description: 'Banner text body',
        }),
    };

    return podcastReportBanner ? (
        <Box sx={{ backgroundColor: theme.tritonColors.darkBlue, color: theme.tritonColors.veryLightBlue, py: 1 }}>
            <Container
                css={{
                    textAlign: 'center',
                }}
            >
                <a href={href} target="_blank" rel="noopener noreferrer">
                    <span css={{ textDecoration: 'underline' }}>{bodyText.download}</span> {bodyText.message}
                </a>
            </Container>
        </Box>
    ) : (
        <></>
    );
};
