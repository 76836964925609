import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { alpha, Box, Card, CardActionArea, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { type GetServerSideProps, type NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import * as React from 'react';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { Flag } from 'components/Flag';
import { contactEmail, Header } from 'components/Header';
import { useLocalizationContext } from 'components/LocalizationProvider';
import { PromoBanner } from 'components/PromoBanner';
import { TritonFooter } from 'components/TritonFooter';
import { easingGradient } from 'helpers/gradient';
import { addCacheControlHeader, cacheDurationSeconds } from 'helpers/headers';
import { getRankerUrl } from 'helpers/urls';
import ArrowCircleUrl from 'images/arrow-circle.svg?url';
import IabTechLabSeal from 'images/certification-logos/iab-tech-lab-seal_NEW_footer.png';
import Hero from 'images/Hero.avif';
import IconEmailUrl from 'images/icon-email.svg?url';
import IconReportUrl from 'images/icon-report.svg?url';
import IconResourcesUrl from 'images/icon-resources.svg?url';
import { type ReportSet, regionIds, regionLabels, getReportSetsByRegionId, getReportSetTitle } from 'models/report';
import { prisma } from 'prismaClient';
import { theme } from 'styles/mui-theme';
import { HeadingThree, HeadingTwo, TritonButton, TritonButtonFilled, TritonDivider } from 'styles/styles';

type PageProps = {
    reportSetIdsToShow: string[];
    podcastReportBanner: boolean;
};

export const siteTitle = defineMessage({
    defaultMessage: 'Triton Digital Podcast Rankers',
    description: 'Site title',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Home: NextPage<PageProps> = ({ reportSetIdsToShow, podcastReportBanner }) => {
    const intl = useIntl();
    const { locale } = useLocalizationContext();

    const pageTitle = intl.formatMessage(siteTitle);

    const ResourceLinks: { label: string; href: string }[] = [];

    ResourceLinks.push(
        ...[
            {
                label: intl.formatMessage({
                    defaultMessage: '2024 LATAM Insights Report (English)',
                    description: 'Resource link',
                }),
                href: '/Triton Digital 2024 LATAM Insights Report.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: '2024 Canadian Podcast Report (English)',
                    description: 'Resource link',
                }),
                href: '/Triton Digital 2024 Canada Podcast Report.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: '2024 Canadian Podcast Report (French)',
                    description: 'Resource link',
                }),
                href: '/Triton Digital 2024 Rapport des Podcasts au Canada.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: '2023 Podcast Trends in Canada (English)',
                    description: 'Resource link',
                }),
                href: '/Triton Digital 2023 Canada Podcast Trends Report.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: '2023 Podcast Trends in Canada (French)',
                    description: 'Resource link',
                }),
                href: '/Triton Digital 2023 Tendances Des Podcasts Au Canada.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: '2023 U.S. Podcast Report',
                    description: 'Resource link',
                }),
                href: 'https://info.tritondigital.com/hubfs/Triton%20Digital%20U.S.%20Podcast%20Report%202023.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: '2022 Year-End U.S. Podcast Report',
                    description: 'Resource link',
                }),
                href: '/2022 U.S. Podcast Report.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: '2024 Podcast Reporting Period Calendar',
                    description: 'Resource link',
                }),
                href: '/2024 Podcast Reporting Periods by Region.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Podcast Metrics Description of Methodology',
                    description: 'Resource link',
                }),
                href: 'https://tritondigitalv3.blob.core.windows.net/media/Default/Resources/2020-TD-Methodology-PCM-v4-final.pdf',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Ranker Reissuance History',
                    description: 'Resource link',
                }),
                href: '/report-reissuance-history',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Podcast Ranker Media Kit',
                    description: 'Resource link',
                }),
                href: 'https://www.beautiful.ai/player/-McikczI7dUn4kDJXnvh',
            },
        ],
    );

    return (
        <>
            <Head>
                <title>{pageTitle}</title>
            </Head>
            <PromoBanner podcastReportBanner={podcastReportBanner} locale={locale} intl={intl} />
            <Box
                sx={{
                    mx: 'auto',
                    py: 2,
                    overflow: 'hidden',
                    position: 'relative',
                    '&::after': {
                        bottom: 0,
                        content: '""',
                        overflow: 'hidden',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '100vw',
                        height: '100%',
                        zIndex: -1,
                        backgroundImage: `url(${Hero.src})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '2000px', // Move off-screen to the right
                        backgroundSize: '0% 0%',
                        opacity: 0,
                        transition: 'background-position 0.7s ease, background-size 0.7s ease',

                        [theme.breakpoints.up('md')]: {
                            backgroundPosition: '25%',
                            backgroundSize: 'cover',
                            opacity: 1,
                        },

                        [theme.breakpoints.up('lg')]: {
                            backgroundPosition: '5vw',
                            backgroundSize: 'cover',
                        },
                    },
                }}
            >
                <Container>
                    <Stack>
                        <Header hideLinks />
                        <Grid
                            container
                            sx={{
                                my: {
                                    xs: 4,
                                    md: 8,
                                },
                                [theme.breakpoints.down('md')]: {
                                    textAlign: 'center',
                                },
                            }}
                        >
                            <Grid item xs={12} md={7} lg={6}>
                                <Stack spacing={4}>
                                    <HeadingTwo>{pageTitle}</HeadingTwo>
                                    <HeadingThree>
                                        <FormattedMessage
                                            defaultMessage="Triton Digital's Podcast Rankers list the Top Podcasts in a particular
                                            country or region, as measured by Triton's Podcast Metrics measurement
                                            service."
                                            description="Hero subheading"
                                        />
                                    </HeadingThree>
                                    <Typography>
                                        <FormattedMessage
                                            defaultMessage="Podcast Rankers are produced monthly on a per country or region basis. The
                                            entities listed are ranked by Downloads and/or Listeners/Users, in accordance
                                            with the IAB Tech Lab's Podcast Technical Measurement Guidelines. Reported
                                            companies must opt-in to the public release of their metrics."
                                            description="Hero body text"
                                        />
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        gap={2}
                                        alignItems="center"
                                        sx={{
                                            [theme.breakpoints.down('md')]: {
                                                justifyContent: 'center',
                                            },
                                        }}
                                    >
                                        <TritonButtonFilled size="large" href="#rankers" LinkComponent={Link}>
                                            <FormattedMessage
                                                defaultMessage="View rankers"
                                                description="Landing page call to action to view rankers"
                                            />
                                        </TritonButtonFilled>
                                        <TritonButton size="large" href="#subscribe" LinkComponent={Link}>
                                            <FormattedMessage
                                                defaultMessage="Subscribe"
                                                description="Landing page call to action to subscribe"
                                            />
                                        </TritonButton>
                                        <TechLabLogo
                                            css={{
                                                width: 120,
                                                height: 'fit-content',
                                                [theme.breakpoints.down('md')]: {
                                                    display: 'none',
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
            <Box py={2}>
                <Container>
                    <Stack>
                        <Box
                            sx={{
                                position: 'relative',
                                textAlign: 'center',
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    width: '100%',
                                    height: '1px',
                                    background: theme.tritonColors.skyBlue,
                                    position: 'absolute',
                                    top: '50%',
                                },
                                [theme.breakpoints.up('md')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <TechLabLogo
                                css={{
                                    width: 150,
                                    height: 'auto',
                                    background: 'white',
                                    padding: '0 20px',
                                    position: 'relative',
                                }}
                            />
                        </Box>
                        <Box
                            id="rankers"
                            sx={{
                                mt: {
                                    xs: 4,
                                    md: 10,
                                },
                                mb: 12,
                            }}
                        >
                            <Stack spacing={2}>
                                <HeadingTwo
                                    css={{
                                        textAlign: 'center',
                                        marginBottom: '0.5em',
                                    }}
                                >
                                    <FormattedMessage
                                        defaultMessage="Our Podcast Rankers"
                                        description="Landing page heading for rankers"
                                    />
                                </HeadingTwo>
                                <Box>
                                    <Grid container spacing={3} alignItems="stretch">
                                        {regionIds.map((regionId) => {
                                            return (
                                                <Grid key={regionId} container item lg={3} md={4} sm={6} xs={12}>
                                                    <Card
                                                        variant="outlined"
                                                        css={{
                                                            background: theme.tritonColors.skyBlue,
                                                            width: '100%',
                                                            border: 'none',
                                                            borderRadius: 0,
                                                        }}
                                                    >
                                                        <CardActionArea
                                                            LinkComponent={Link}
                                                            href={getRankerUrl({
                                                                regionId,
                                                            })}
                                                            css={{
                                                                height: '100%',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                '&:hover .card-cta': {
                                                                    background: theme.tritonColors.paleBlue,
                                                                },
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    height: 150,
                                                                    position: 'relative',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                <Flag
                                                                    regionId={regionId}
                                                                    title={regionLabels[regionId]}
                                                                    css={{
                                                                        position: 'absolute',
                                                                        transform: 'scale(1.3)',
                                                                        transformOrigin: 'left top',
                                                                        height: '100%',
                                                                        maskImage: `linear-gradient(90deg, ${easingGradient})`,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <CardContent sx={{ width: '100%', flex: '1 0 auto' }}>
                                                                <Typography
                                                                    gutterBottom
                                                                    variant="h5"
                                                                    component="div"
                                                                    color="white"
                                                                    sx={{
                                                                        borderBottom: `1px solid ${alpha(
                                                                            '#ffffff',
                                                                            0.7,
                                                                        )}`,
                                                                        pb: 1,
                                                                        mb: 1,
                                                                    }}
                                                                >
                                                                    {regionLabels[regionId]}
                                                                </Typography>
                                                                {getReportSetsByRegionId(regionId).map((reportSet) => {
                                                                    const id = getReportSetId(reportSet);

                                                                    if (!reportSetIdsToShow.includes(id)) {
                                                                        return null;
                                                                    }

                                                                    const title = getReportSetHomePageTitle(reportSet);

                                                                    if (!title) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <Typography
                                                                            key={title}
                                                                            variant="body2"
                                                                            color="white"
                                                                            css={{
                                                                                opacity: 0.7,
                                                                            }}
                                                                        >
                                                                            {title}
                                                                        </Typography>
                                                                    );
                                                                })}
                                                            </CardContent>
                                                            <Box
                                                                className="card-cta"
                                                                sx={{
                                                                    background: theme.tritonColors.darkBlue,
                                                                    color: 'white',
                                                                    width: '100%',
                                                                    textTransform: 'uppercase',
                                                                    padding: 2,
                                                                    textAlign: 'center',
                                                                    transition: 'all 0.3s ease-in-out',
                                                                }}
                                                            >
                                                                <FormatListNumberedIcon
                                                                    css={{ verticalAlign: 'middle', marginTop: -4 }}
                                                                />{' '}
                                                                <FormattedMessage
                                                                    defaultMessage="View rankers"
                                                                    description="Landing page link to go to a country's ranker"
                                                                />
                                                            </Box>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </Stack>
                        </Box>
                        <Grid
                            container
                            spacing={6}
                            mb={20}
                            css={{
                                [theme.breakpoints.down('sm')]: {
                                    textAlign: 'center',
                                    '& svg': {
                                        margin: '0 auto',
                                    },
                                },
                            }}
                        >
                            <Grid item container xs={12} id="resources">
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    spacing={2}
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <Grid item xs={12} sm="auto">
                                        <img src={IconResourcesUrl} alt="Resources" width={60} height={60} />
                                    </Grid>
                                    <Grid item xs={12} sm>
                                        <Stack spacing={2}>
                                            <HeadingThree>
                                                <FormattedMessage
                                                    defaultMessage="Resources"
                                                    description="Landing page heading for resources"
                                                />
                                            </HeadingThree>
                                            <Stack spacing={1}>
                                                {ResourceLinks.map((link) => (
                                                    <ResourceLink key={link.href} href={link.href}>
                                                        {link.label}
                                                    </ResourceLink>
                                                ))}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    spacing={2}
                                    sx={{
                                        [theme.breakpoints.down('md')]: {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <Grid item sm="auto">
                                        <img src={IconResourcesUrl} alt="Resources" width={60} height={60} />
                                    </Grid>
                                    <Grid item sm={2}>
                                        <HeadingThree>
                                            <FormattedMessage
                                                defaultMessage="Resources"
                                                description="Landing page heading for resources"
                                            />
                                        </HeadingThree>
                                    </Grid>
                                    <Grid item sm container spacing={1}>
                                        {ResourceLinks.map((link) => (
                                            <Grid item md={6} xs={12} key={link.href}>
                                                <ResourceLink href={link.href}>{link.label}</ResourceLink>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TritonDivider sx={{ width: '100%' }} />
                            </Grid>
                            <Grid item container xs={12} sm id="subscribe" spacing={2}>
                                <Grid item xs={12} sm="auto">
                                    <img src={IconEmailUrl} alt="Email" width={60} height={60} />
                                </Grid>
                                <Grid item xs={12} sm>
                                    <Stack spacing={2}>
                                        <HeadingThree>
                                            <FormattedMessage
                                                defaultMessage="Receive podcast rankers via email"
                                                description="Landing page heading for email subscription"
                                            />
                                        </HeadingThree>
                                        <Typography>
                                            <FormattedMessage
                                                defaultMessage="Learn about the top-performing streaming audio stations, networks, and
                                                podcasts around the world when new rankers are published."
                                                description="Landing page body for email subscription"
                                            />
                                        </Typography>
                                        <Box>
                                            <a
                                                href="https://info.tritondigital.com/subscribe-to-podcast-reports-and-webcast-metrics-rankers"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <LinkWithArrow>
                                                    <FormattedMessage
                                                        defaultMessage="Subscribe to email"
                                                        description="Landing page call to action for email subscription"
                                                    />
                                                </LinkWithArrow>
                                            </a>
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <TritonDivider
                                    sx={{
                                        height: '100%',
                                        [theme.breakpoints.up('md')]: {
                                            borderBottomWidth: 0,
                                            borderRightWidth: 1,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={12} sm id="join" spacing={2}>
                                <Grid item xs={12} sm="auto">
                                    <img src={IconReportUrl} alt="Report" width={60} height={60} />
                                </Grid>
                                <Grid item xs={12} sm>
                                    <Stack spacing={2}>
                                        <HeadingThree>
                                            <FormattedMessage
                                                defaultMessage="Join the Podcast Ranker"
                                                description="Landing page heading for joining the Ranker"
                                            />
                                        </HeadingThree>
                                        <Typography>
                                            <FormattedMessage
                                                defaultMessage="Any subscribing podcast network or publisher with an audience in any of the
                                                reported regions are eligible to participate in our Podcast Rankers."
                                                description="Landing page body for joining the Ranker"
                                            />
                                        </Typography>
                                        <Box>
                                            <a href={contactEmail} target="_blank" rel="noreferrer">
                                                <LinkWithArrow>
                                                    <FormattedMessage
                                                        defaultMessage="Join Podcast Ranker"
                                                        description="Landing page call to action for joining the ranker"
                                                    />
                                                </LinkWithArrow>
                                            </a>
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
            <TritonFooter />
        </>
    );
};

export default Home;

export const getServerSideProps: GetServerSideProps<PageProps> = async (context) => {
    addCacheControlHeader(context.res, cacheDurationSeconds.landingPage);

    const now = new Date();

    const reports = await prisma.report.groupBy({
        by: ['regionId', 'slug'],
        where: {
            OR: [
                {
                    availableFrom: {
                        lte: now,
                    },
                },
                {
                    availableFrom: null,
                },
            ],
        },
    });

    const reportSetIdsToShow = reports.map(({ regionId, slug }) => getReportSetId({ regionId, slug }));

    // Show promo between Junuary 28th 9AM ET and February 28th
    const podcastReportBanner =
        now >= new Date('2025-01-28T09:00:00.000-05:00') && now < new Date('2025-04-01T09:00:00.000-05:00');

    return {
        props: {
            reportSetIdsToShow,
            podcastReportBanner,
        },
    };
};

const LinkWithArrow = ({ children }: React.PropsWithChildren<unknown>): React.ReactElement => (
    <Stack
        direction="row"
        spacing={1}
        css={{
            display: 'inline-flex',
            img: {
                transition: 'transform 0.1s ease-in-out',
            },
            '&:hover': {
                img: {
                    transform: 'translateX(5px)',
                },
            },
        }}
    >
        <Typography sx={{ fontSize: 18, color: theme.tritonColors.darkBlue }}>{children}</Typography>
        <img src={ArrowCircleUrl} alt="Arrow" height={24} width={24} />
    </Stack>
);

const TechLabLogo = ({ className }: { className?: string }): React.ReactElement => (
    <img
        alt="IAB Tech Lab Standards Compliant Verified"
        src={IabTechLabSeal.src}
        width={320}
        height={400}
        className={className}
    />
);

const ResourceLink = ({ href, children }: React.PropsWithChildren<{ href: string }>): React.ReactElement => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        <strong css={{ textDecoration: 'underline', fontWeight: 'normal' }}>{children}</strong>
    </a>
);

function getReportSetId({ regionId, slug }: { regionId: string; slug: string }) {
    return regionId + '-' + slug;
}

function getReportSetHomePageTitle(reportSet: ReportSet): string | null {
    switch (reportSet.regionId) {
        case 'us': {
            switch (reportSet.slug) {
                case 'networks-weekly-downloads': {
                    return 'Top Sales Networks';
                }

                case 'podcasts-weekly-downloads': {
                    return 'Top Podcasts';
                }

                case 'networks-weekly-users':
                case 'podcasts-weekly-users': {
                    return null;
                }
            }

            break;
        }

        default: {
            return getReportSetTitle(reportSet);
        }
    }
}
